import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
// make sure to set default timezone
let guessedTZ = dayjs.tz.guess();

import {getCompanyValByKey} from "@/helpers/companyHelper";

export function getTimeZone() {
    return dayjs.tz.guess();
}
export function getDateToday() {
    return dayjs().format('YYYY-MM-DD');
}
export function getDateTimeToday() {
    return dayjs().format('YYYY-MM-DD HH:mm');
}
export function getDateFormattedVal(date){
    return dayjs(date, "YYYY-MM-DD").format(getCompanyValByKey('date_format'))
}
export function convertUTCToLocal(date, outputFormat = 'YYYY-MM-DD HH:mm:ss'){
    return dayjs.utc(date, 'YYYY-MM-DD HH:mm:ss').tz(guessedTZ).format(outputFormat);
}
export function convertLocalToUTC(date, outputFormat = 'YYYY-MM-DD HH:mm:ss'){
    return dayjs(date, ['YYYY-MM-DDTHH:mm', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm']).tz(guessedTZ, true).utc().format(outputFormat);
}
export function convertUTCToLocalWithFormat(date){
    return convertUTCToLocal(date, getCompanyValByKey('date_format') + ' HH:mm');
}