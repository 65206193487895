<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <slot name="nav"></slot>
                    <h5 class="m-0 heading-border-bottom">{{ pageName }}</h5>
                </div><!-- /.col -->
                <div class="col-sm-6" v-if="segments.length > 1">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item" v-for="segment in segments" :key="segment.id" :class="!segment.link && ' active'">
                            <router-link  :to="{ name: segment.routeName }" v-if="segment.link">{{ segment.text}}</router-link>
                            <span v-else>{{ segment.text}}</span>
                        </li>
                        <!--<li class="breadcrumb-item active">Dashboard</li>-->
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
</template>

<script>
    export default {
        name: "BreadCrumb",
        props: {
            pageName: String,
            segments: Array
        },
        created() {

        }
    }
</script>

<style scoped>

</style>