<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content pl-2 pr-2">
            <logs></logs>
        </section>
    </div>
</template>

<script>
import Logs from "@/components/app/common/Logs";
import BreadCrumb from "@/components/app/common/BreadCrumb";
export default {
    name: "Logs_view",
    components: {BreadCrumb, Logs},
    data: function() {
        return {
            pageName: 'Logs',
            segments: [
                { link: false, text: 'Logs' },
            ],
        }
    },
}
</script>

<style scoped>

</style>