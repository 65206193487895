<template>
    <section class="content pl-2 pr-2">
        <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additional-buttons="additionalButtons" ref="mainDataTable"></CustomDataTable>
    </section>
</template>

<script>
import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
import CustomDataTable from "@/components/app/table/CustomDataTable";

export default {
    name: "Logs",
    components: {CustomDataTable},
    data: function() {
        return {
            tableDataUrl: this.$globalSettings.api.endpoints.log.logsDatatable,
            columns: [
                {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                {label: 'Date', name: 'date', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name]) },
                {label: 'Entity Type', name: 'entity_type', orderable: true},
                {label: 'Entity ID', name: 'entity_id', orderable: true},
                {label: 'Action', name: 'action', orderable: true},
                {label: 'User', name: 'user_name', orderable: true},
            ],
            searchFilters:{
                search: '',
                length: 10,
                column: 'date',
                dir: 'desc'
            },
            additionalButtons: [],

        }
    },
    props: {
        /*showActionButtons:{
            type: Boolean,
            default: () => true
        },*/
    },
    methods: {

    },
    mounted() {
        if(this.showActionButtons){
            //null
        }
        if(this.showAdditionalButtons) {
            //null
        }

    }
}
</script>

<style scoped>

</style>